import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";

const MyMap = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB6Cq6kyiyyB3lRrKnClAgKfISFw-dQyww&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div className='map' style={{ height: "600px", marginLeft: "-15px", marginRight: "-15px" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 50.962120, lng: 7.113450 }}
    defaultOptions={{ styles: props.styles }}
  >
    {/* Franz-Becher-Straße 17, 51491 Overath, Deutschland */}
    <MarkerWithLabel
      position={{ lat: 50.93204, lng: 7.2786 }}
      icon={'assets/map-labal-arrow.png'}
      labelAnchor={new google.maps.Point(50, 100)}
      labelStyle={props.makerLabelStyle}
      clickable={false}
    >
      <div className='map-marker'>
        <h6>Standort Overath</h6>
        <p className='m-0'>Franz-Becher Straße 17, 51491 Overath</p>
      </div>
    </MarkerWithLabel>

    {/* Richard Byrd Straße 31, 50829 Köln */}
    <MarkerWithLabel
      position={{ lat: 50.989071, lng: 6.8876461 }}
      labelAnchor={new google.maps.Point(50, 100)}
      icon={'assets/map-labal-arrow.png'}
      labelStyle={props.makerLabelStyle}
      clickable={false}
    >
      <div className='map-marker'>
        <h6>Standort Köln</h6>
        <p className='m-0'>Richard Byrd Straße 31, 50829 Köln</p>
      </div>
    </MarkerWithLabel>
  </GoogleMap>
));

class Map extends Component {
  constructor(props) {
    super(props);

    this.makerLabelStyle =  {
      backgroundColor: "#0f202c",
      color: '#fff',
      padding: "10px 20px",
      borderRadius: '10px'
    };

    this.mapStyles =  [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#f7f9ff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ];
  }

  render() {
    return (
      <MyMap
        styles={this.mapStyles}
        makerLabelStyle={this.makerLabelStyle}
      />
    );
  }
}

export default Map;
