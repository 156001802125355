import React from "react";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";
import axios from "axios";
import qs from 'qs';

import {
    Alert,
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    FormGroup,
    FormText,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";

import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import Map from "../components/Map";
import config from "../../data/SiteConfig";

import "../../scss/pages/home.scss";

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            submitSuccessfully: null,
            submitError: false
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.formValidating = this.formValidating.bind(this);
        this.getInvalidInputClass = this.getInvalidInputClass.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getInvalidInputClass(error) {
        if (error) return "is-invalid";
        return "";
    }


    sendEmail(data) {
        axios.post(
            "https://emes-consulting.com/sendEmail.php",
            qs.stringify(data),
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        )
            .then((response) => {
                if (response.data.ok) {
                    this.setState({
                        submitSuccessfully: true,
                        submitError: false
                    });
                } else {
                    this.setState({
                        submitSuccessfully: false,
                        submitError: true
                    });
                }
            })
            .catch((error) => {
                console.error(error); // eslint-disable-line
                this.setState({
                    submitSuccessfully: false,
                    submitError: true
                });
            });
    }

    formValidating(values) {
        const errors = {};

        if (!values.email) errors.email = "Ihre E-Mail-Adresse wird benötigt.";
        if (!values.name) errors.name = "Ihr Name wird benötigt.";
        if (!values.message) errors.message = "Sie müssen uns was mitteilen.";
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) errors.email = "Sie müssen eine echte E-Mail-Adresse angeben";
        return errors;
    }


    render() {
        return (
            <Layout>
                <Helmet title={config.siteTitle}/>
                <SEO {...this.props} />

                <section id='home'>
                    <Row>
                        <Col lg={{size: 6, offset: 1}} md={12} sm={12}>
                            <h2>
                                <span>Wir beraten Sie!</span>
                            </h2>
                            <h4>
                                <span>Lösungsorientiert. Praxisnah. Effizient.</span>
                            </h4>
                            <p>
                                Unsere Experten aus den verschiedenen Fachbereichen<br/>
                                beraten Sie gerne im Bereich Datenschutz<br/>
                                undunterstützen Sie bei der Umsetzung Ihrer<br/>
                                Social Media Kampagnen. 
                            </p>
                        </Col>
                        <Img className='home-bg d-none d-lg-block'
                             fixed={this.props.data.headerImage.childImageSharp.fixed}/>
                    </Row>
                </section>

                <section id='service'>
                    <Img className='service-interferers d-none d-md-block'
                         fixed={this.props.data.serviceInterferers.childImageSharp.fixed}/>
                    <Row>
                        <Col md={{size: 8, offset: 2}} sm={12}>
                            <h2 className="mb-5">
                                <i className='header-dot'/>
                                {" "}
                                Datenschutz
                            </h2>
                            <p>
                                Die EMES Consulting UG verfügt über ein Team von TÜV zertifizierten Datenschützern, IT
                                Experten und Volljuristen mit Standorten in Köln und Overath.
                            </p><p>
                            Oftmals ist es so, dass in Unternehmen die notwendigen personellen Mittel und das
                            juristische Know How auf dem Gebiet des Datenschutzes fehlen. Es ist schwierig diesen mit
                            verhältnismäßig geringem Aufwand und auf eine effiziente Art und Weise zu gewährleisten,
                            ohne, dass der eigentliche Betrieb des Unternehmens beeinträchtigt wird.
                        </p><p>
                            Genau aus diesem Grund, haben wir es uns zur Aufgabe gemacht, Unternehmen bei der Umsetzung
                            der Datenschutzrichtlinien, gemäß den Vorgaben der EU zu beraten und Mitarbeiter aktiv zu
                            schulen.
                        </p><p>
                            Unser Team ist bestens in der Datenschutzbranche verknüpft und arbeitet effizient sowie
                            praxisnah. Wir helfen Ihnen mit unserem Know How in den Bereichen Datenschutz und IT,
                            zielorientiert und kostenoptimiert, die für Ihren Fall beste Lösung zu finden.
                        </p>
                        </Col>

                        <Col md={{size: 10, offset: 2}} sm={12}>
                            <Nav tabs className='mt-5'>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === "1"})}
                                        onClick={() => {
                                            this.toggleTab("1");
                                        }}
                                    >
                                        Datenschutzmandat
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === "2"})}
                                        onClick={() => {
                                            this.toggleTab("2");
                                        }}
                                    >
                                        Datenschutzberatung
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === "3"})}
                                        onClick={() => {
                                            this.toggleTab("3");
                                        }}
                                    >
                                        Schulungen
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col className='text-center' md={3}>
                                            <Img fixed={this.props.data.iconClipboard.childImageSharp.fixed}/>
                                        </Col>
                                        <Col md={9}>
                                            <p>
                                                Wir verstehen es als unsere Aufgabe, im Rahmen unserer Tätigkeiten auf
                                                die Einhaltung der Vorschriften der DSGVO und des BDSG hinzuwirken.
                                                Diese ergeben sich aus den Art. 37-39 der DSGVO.
                                            </p>
                                            <p>
                                                In unserer Funktion als externe betriebliche Datenschutzbeauftragte
                                                übernehmen wir für ihr Unternehmen insbesondere die folgenden Aufgaben:
                                                <ul>
                                                    <li>Die Überwachung des Umfangs sowie der Verfahren, Methoden und
                                                        Prozesse, mit deren Hilfe personenbezogene Daten verarbeitet
                                                        werden.
                                                    </li>
                                                    <li>Die Einweisung der mit der Datenverarbeitung betrauten Personen
                                                        und Unterrichtung über die datenschutzrechtlichen Grundlagen,
                                                        sowie Verpflichtung der Mitarbeiter auf das Datengeheimnis.
                                                    </li>
                                                    <li>Die Überwachung und Koordinierung der technischen und
                                                        organisatorischen Maßnahmen, die zur Sicherstellung des
                                                        Datenschutzes erforderlich sind.
                                                    </li>
                                                    <li>Die Einhaltung des Grundsatzes der Datenvermeidung und
                                                        Datensparsamkeit in Bezug auf personenbezogene Daten
                                                        sicherzustellen.
                                                    </li>
                                                    <li>Die Vertretung des Unternehmens gegenüber Externen in bzw. zu
                                                        Fragen des Datenschutzes (z.B. gegenüber den Aufsichtsbehörden).
                                                    </li>

                                                </ul>
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col className='text-center' md={3}>
                                            <Img fixed={this.props.data.iconPlotGraph.childImageSharp.fixed}/>
                                        </Col>
                                        <Col ccmd={9}>
                                            <p>
                                                Sie sind auf der Suche, nach einem starken und vertrauensvollen Partner
                                                an Ihrer Seite, dessen Beratung über das eDSB Mandat hinausgeht? Dann
                                                sind sie bei uns genau richtig. Wir begleiten sie in allen Bereichen des
                                                Datenschutzes und helfen Ihnen, Ihre Ziele zu gestalten und umzusetzten.
                                            </p>
                                            <p>
                                                Dabei geht es besonders um:
                                                <ul>
                                                    <li>die Erstellung, Gestaltung und Überprüfung der vorgeschriebenen
                                                        Dokumentation (insb. das Verzeichnis von
                                                        Verarbeitungstätigkeiten)
                                                    </li>
                                                    <li>die Vorbereitung und Dokumentation für Audits durch einen Kunden
                                                        oder eine Aufsichtsbehörde
                                                    </li>
                                                    <li>die Mitgestaltung von Betriebsvereinbarungen</li>
                                                    <li>den Aufbau eines Datenschutz-Management-Konzeptes</li>
                                                    <li>die Entwicklung eines Datenpannenmanagements</li>
                                                </ul>


                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col className='text-center' md={3}>
                                            <Img fixed={this.props.data.iconSign.childImageSharp.fixed}/>
                                        </Col>
                                        <Col md={9}>
                                            <p>
                                                Zur Gewährleistung eines angemessenen Datenschutzniveaus in Ihrem
                                                Unternehmen, ist es wichtig, dass Ihre Mitarbeiter im Bereich des
                                                Datenschutzes regelmäßig geschult werden.
                                            </p><p>
                                            Wir werden gemeinsam mit Ihnen ein geeignetes Schulungskonzept erstellen und
                                            bereiten, je nach Verantwortung und Aufgaben ihrer Mitarbeiter
                                            maßgeschneiderte Schulungsunterlagen vor.
                                        </p><p>
                                            Während der anschließenden Schulungen wird unser qualifiziertes Fachpersonal
                                            ausführlich auf ihre Fragen und Bedürfnisse eingehen. Außerdem werden wir
                                            mit den Mitarbeitern konkrete Fallbeispiele aus der Praxis Ihres
                                            Unternehmens analysieren und besprechen.
                                        </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                    <Img className='service-bg d-none d-md-block'
                         fixed={this.props.data.serviceImage.childImageSharp.fixed}/>
                </section>
                <section id='method'>
                    <Img className='method-bg d-none d-md-block'
                         fixed={this.props.data.methodImage.childImageSharp.fixed}/>
                    <Img className='method-interferers d-none d-md-block'
                         fixed={this.props.data.methodInterferers.childImageSharp.fixed}/>
                    <Row>
                        <Col md={{size: 6, offset: 2}} sm={12}>
                            <h2 className='text-thin'>
                                <i className='header-dot'/>
                                {" "}
                                Step by Step zur <br/>
                                Social Media Kampagne
                            </h2>
                        </Col>

                        <Col className='method-wrapper p-0' md={{size: 6, offset: 4}} sm={12}>
                            <div className='shadow-left'/>
                            <div className='shadow-right'/>

                            <div className='method-flow pl-3 pr-3 mt-5'>
                                <div className='flow-line'/>
                                <div className='step text-left'>

                                    <div className='stop-dot'/>
                                    <h3>Ist-Zustand</h3>
                                    <p>
                                        Egal, ob Sie den Social Media Auftritt ihres Unternehmens erst aufbauen wollen, den bestehenden 
                                        Auftritt verbessern oder konkrete Kampagnen umsetzen möchten, unser Team aus kreativen Köpfen 
                                        und analytischen Zahlenliebhabern, macht sich ein klares Bild der Situation und schätzt das 
                                        Potential und die Möglichkeiten ein.
                                    </p>
                                </div>
                                <div className='step text-right'>
                                    <div className='stop-dot'/>
                                    <h3>Planung</h3>
                                    <p>
                                        Gemeinsam lernen wir Ihre Zielgruppe kennen, bestimmen die passenden Kanäle und erschaffen ein ganzheitliches 
                                        Konzept, welches die Zielgruppe abholt und zu treuen Kunden werden lässt. Zusätzlich stecken wir uns Zwischenziele, 
                                        um die Erfolge Step by Step sichtbar werden zu lassen.
                                    </p>
                                </div>
                                <div className='step text-left'>
                                    <div className='stop-dot'/>
                                    <h3>Umsetzung</h3>
                                    <p>
                                        Wir richten die passenden Social Media Kanäle ein, kreieren herausragenden Content, 
                                        haben unsere Monitoring Tools stets im Auge und  stellen die Kommunikation zwischen allen Beteiligten sicher. 
                                        Zusätzlich übernehmen wir gerne auch das Community Managment und schaffen so eine starke Bindung zwischen 
                                        Ihrer Marke und den Kunden.
                                    </p>
                                </div>
                                <div className='step text-right'>
                                    <div className='stop-dot'/>
                                    <h3>Auswertung</h3>
                                    <p>
                                        Das Feedback der Community dient als ein Parameter in der Erfolgsmessung. Zusätzlich stellen unsere 
                                        Zahlenfreaks Ihnen aber gerne eine Übersicht aus Allen, für Sie relevanten Kennzahlen, zusammen, 
                                        anhand derer bestimmte Schritte optimiert werden können und Learnings gezogen werden.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
                <section id='references'>
                    <Img className='references-bg d-none d-md-block'
                         fixed={this.props.data.referenzenInterferers.childImageSharp.fixed}/>
                    <Row>
                        <Col md={{size: 8, offset: 2}} sm={12}>
                            <h2 className='text-thin'>
                                <i className='header-dot'/>
                                {" "}
                                Referenzen
                            </h2>

                            <Row className='customer'>
                                <Col md={4} sm={12}>
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/helot2-logo.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                Helot GmbH
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4} sm={12} >
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/impect-logo.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                Impect GmbH
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={4} sm={12} >
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/dm.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                DataMedis GmbH
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='customer'>
                                <Col md={4} sm={12}>
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/pkm.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                PKM GmbH
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4} sm={12} >
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/zmi.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                ZM Imports UG
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={4} sm={12} >
                                    <Card>
                                        <img className='mt-5' width="100%"
                                             src="https://emes-consulting.com/kgv-logo.png"/>
                                        <CardBody>
                                            <CardText className='text-center'>
                                                KGV GmbH
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </section>
                <section id='contact' name='contact'>
                    <Img className='contact-interferers d-none d-md-block'
                         fixed={this.props.data.contactInterferers.childImageSharp.fixed}/>
                    <Row>
                        <Col md={{size: 8, offset: 2}} sm={12}>
                            <h2 className='text-thin'>
                                <i className='header-dot'/>
                                {" "}
                                Kontakt
                            </h2>
                            <p>
                                Bei Fragen oder dem Wunsch eines unverbindlichen Erstgesprächs, rufen sie uns jederzeit an unter 0221/ 3555 444.
                            </p>

                            <Formik
                                initialValues={{email: "", name: "", address: "", message: ""}}
                                validate={this.formValidating}
                                onSubmit={this.sendEmail}
                            >
                                {({
                                      errors,
                                      isSubmitting
                                  }) => (
                                    <Form>
                                        <Row>
                                            {this.state.submitSuccessfully ?
                                                <Col md={12}>
                                                    <Alert color="success">
                                                        Vielen Dank für Ihre Kontaktaufnahme!
                                                    </Alert>
                                                </Col> : null}

                                            {this.state.submitError ?
                                                <Col md={12}>
                                                    <Alert color="danger">
                                                        Es ist ein interner Server Fehler aufgetreten.
                                                    </Alert>
                                                </Col> : null}
                                            <Col md={6} sm={12}>
                                                <FormGroup className={this.getInvalidInputClass(errors.email)}>
                                                    <Field
                                                        className='form-control'
                                                        type="email"
                                                        name="email"
                                                        placeholder="Deine Email"
                                                    />
                                                    <ErrorMessage name="email">{(msg) => <FormText
                                                        color="danger">{msg}</FormText>}</ErrorMessage>
                                                </FormGroup>
                                                <FormGroup className={this.getInvalidInputClass(errors.name)}>
                                                    <Field
                                                        className='form-control'
                                                        type="text"
                                                        name="name"
                                                        placeholder="Dein Name"
                                                    />
                                                    <ErrorMessage name="name">{(msg) => <FormText
                                                        color="danger">{msg}</FormText>}</ErrorMessage>
                                                </FormGroup>
                                                <FormGroup className={this.getInvalidInputClass(errors.address)}>
                                                    <Field
                                                        className='form-control'
                                                        type="text"
                                                        name="address"
                                                        placeholder="Anschrift"
                                                    />
                                                    <ErrorMessage name="address">{(msg) =>
                                                        <FormText color="danger">{msg}</FormText>}
                                                    </ErrorMessage>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} sm={12} className='d-none d-md-block'>
                                                <Img className='contact-image'
                                                     fixed={this.props.data.contactImage.childImageSharp.fixed}/>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup className={this.getInvalidInputClass(errors.message)}>
                                                    <Field
                                                        className='form-control'
                                                        component="textarea"
                                                        name="message"
                                                        placeholder="Nachricht"
                                                    />
                                                    <ErrorMessage name="message">{(msg) =>
                                                        <FormText color="danger">{msg}</FormText>}
                                                    </ErrorMessage>
                                                </FormGroup>

                                                <Button
                                                    className='mt-3 float-right'
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Senden
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>

                    <Map/>
                </section>
            </Layout>
        );
    }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
    query {
        # header section
        headerImage: file(relativePath: { regex: "/header-image.png/" }) {
            childImageSharp {
                fixed(width: 550) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        # serive section
        serviceInterferers: file(relativePath: { regex: "/service-interferers.png/" }) {
            childImageSharp {
                fixed(width: 100, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        iconClipboard: file(relativePath: { regex: "/clipboard.png/" }) {
            childImageSharp {
                fixed(width: 81, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        iconPlotGraph: file(relativePath: { regex: "/plot-graph.png/" }) {
            childImageSharp {
                fixed(width: 83, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        iconSign: file(relativePath: { regex: "/sign.png/" }) {
            childImageSharp {
                fixed(width: 87, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        serviceImage: file(relativePath: { regex: "/unser-service.png/" }) {
            childImageSharp {
                fixed(width: 600) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        # method section
        methodInterferers: file(relativePath: { regex: "/method-interferers.png/" }) {
            childImageSharp {
                fixed(width: 100, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        methodImage: file(relativePath: { regex: "/method-flow-bg.png/" }) {
            childImageSharp {
                fixed(width: 230) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        # Referenzen section
        referenzenInterferers: file(relativePath: { regex: "/referenzen-interferers.png/" }) {
            childImageSharp {
                fixed(width: 100, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        # Contact section
        contactInterferers: file(relativePath: { regex: "/contact-interferers.png/" }) {
            childImageSharp {
                fixed(width: 100, traceSVG: {color: "#0e202d"}) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
        contactImage: file(relativePath: { regex: "/contact-bg.png/" }) {
            childImageSharp {
                fixed(width: 330) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;

